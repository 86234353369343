import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import WPAPI from 'wpapi';
import Vue3SocialSharingPlugin from 'vue3-social-sharing';



import "./assets/custom.scss";

import metas from './libs/metas.js'

let wp_website

if ( process.env.NODE_ENV === 'development' )
{
	wp_website = 'https://safestsurgery.eu/api'
}
else
{
	wp_website = 'https://safestsurgery.eu/api'
}

//wp_website = 'https://safestsurgery.eu/api'

let api_url = wp_website + '/wp-json/'

let wp = new WPAPI({ 
	endpoint: api_url,
});



let wp_post = function( path, args, callback ) {

    
    fetch(api_url + path, {
        method: 'POST',
        //credentials: 'cross-origin',
        headers: new Headers({
            'Content-Type': 'application/json;charset=UTF-8',
            //'Authorization': 'Basic ' + btoa( 'user:pass'),
            //'X-WP-Nonce' : Ajax.nonce
        }),
        body: JSON.stringify(args),
    }).then( (response ) => {

        response.json().then(data => {
            callback( data )
        });

    });
}

const app = createApp(App)

app.config.globalProperties.$wp = wp;
app.config.globalProperties.wp_post = wp_post;

app.config.globalProperties.$metas = metas
app.config.globalProperties.wp_website = wp_website


app
	.use(Vue3SocialSharingPlugin)
	.use(router)
	.use(VueGtag, {
		config: { 
			id: "G-GWZTL8CHV2",
		},
	}, router)
	.mount("#app");
<template>
    <div>
        <NavbarTwo />
        <MainBanner />
        <AboutShort/>
        <JoinSection/>
        <HomeApproach />
        <UpdateSection /> 
        <ATrustedPartner />
        <div style="height: 100px;"></div>
    </div>
</template>

<script>
    import NavbarTwo from '../components/Layout/NavbarTwoSafest'
    import MainBanner from '../components/safest/Banner'
    import AboutShort from '../components/safest/AboutShort'
    import HomeApproach from '../components/safest/HomeApproach'
    import UpdateSection from '../components/safest/UpdateSection'
    import ATrustedPartner from '../components/Common/ATrustedPartner'
    import JoinSection from '../components/safest/JoinSection'


    export default {
        components: {
            NavbarTwo,
            MainBanner,
            AboutShort,
            JoinSection,
            HomeApproach,
            UpdateSection,
            ATrustedPartner
        },
        mounted(){
            this.$metas( {
                title : 'Safest | Welcome',
                description : 'Working to improve patient safety along the journey before, during, and after surgery.',
            } )
        }
    }
</script>

<style>

    @media only screen and (max-width : 991px) {
        
    }
</style>
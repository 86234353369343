import { createWebHistory, createRouter } from "vue-router";

// All Pages
import SafestHome from "../pages/Home"
import SafestProject from "../pages/Project"
import Updates from "../pages/Updates";
import Blog from "../pages/Blog";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";

import ContactPage from "../components/Pages/ContactPage";

import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage";

import Glossary from "../components/safest/Glossary";

/*import HomePageOne from "../components/Pages/HomePageOne";
import HomePageTwo from "../components/Pages/HomePageTwo";
import HomePageThree from "../components/Pages/HomePageThree";
import HomePageFour from "../components/Pages/HomePageFour";
import HomePageFive from "../components/Pages/HomePageFive";
import HomePageSix from "../components/Pages/HomePageSix";
import AboutPage from "../components/Pages/AboutPage";
import ServicesPageOne from "../components/Pages/ServicesPageOne";
import ServicesPageTwo from "../components/Pages/ServicesPageTwo";
import WorkPageOne from "../components/Pages/WorkPageOne";
import WorkPageTwo from "../components/Pages/WorkPageTwo";
import WorkPageThree from "../components/Pages/WorkPageThree";
import WorkDetailsPage from "../components/Pages/WorkDetailsPage";
import TeamPage from "../components/Pages/TeamPage";
import PricingPage from "../components/Pages/PricingPage";
import BlogPage from "../components/Pages/BlogPage";*/

const routes = [
  //{ path: "/safest-home", component: SafestHome },
  { path: "/", component: SafestHome },
  { path: "/project", component: SafestProject },
  { path: "/updates", component: Updates },
  { path: "/blog", component: Blog },
  { path: "/blog/:slug", component: BlogDetailsPage },
  { path: "/news/:slug", component: BlogDetailsPage },
  { path: "/project/:slug", component: BlogDetailsPage },
  
  { path: "/glossary", component: Glossary },

  { path: "/contact", component: ContactPage },

  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/terms-conditions", component: TermsOfServicePage },

  { path: "/:cat/:slug", component: BlogDetailsPage },
  { path: "/:page", component: BlogDetailsPage },

  /*{ path: "/home-one", component: HomePageOne },
  { path: "/home-two", component: HomePageTwo },
  { path: "/home-three", component: HomePageThree },
  { path: "/home-four", component: HomePageFour },
  { path: "/home-five", component: HomePageFive },
  { path: "/home-six", component: HomePageSix },
  { path: "/about-us", component: AboutPage },
  { path: "/services", component: ServicesPageOne },
  { path: "/services-one", component: ServicesPageTwo },
  { path: "/work-style-one", component: WorkPageOne },
  { path: "/work-style-two", component: WorkPageTwo },
  { path: "/work-style-three", component: WorkPageThree },
  { path: "/work-details", component: WorkDetailsPage },
  { path: "/team", component: TeamPage },
  { path: "/pricing", component: PricingPage },
  { path: "/blog", component: BlogPage },
  { path: "/blog-details", component: BlogDetailsPage },*/
  
  
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;

<template>
    <div>
        <NavbarTwo />
        <div class="blog-details-area ptb-100">
            <div class="container" v-if="post.title">
                <div class="blog-details-header row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="content">
                            <h1 v-html="post.title.rendered"></h1>
                            <p class="display-date" v-html="post.display_date"></p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="image" v-if="this.featured_media">
                            <img :src="featured_media" alt="image">
                        </div>
                    </div>
                </div>
                <div class="blog-details-desc">
                    <div class="article-content">
                        <ul class="social-links">
                            <li><share-network
                                    network="twitter"
                                    :url="url"
                                    :title="post.title.rendered"
                                    :description="post.excerpt.rendered"
                                    hashtags="Safest"
                                    v-slot="{ share }"
                                >
                                    <div
                                        class="social-network twitter"
                                        @click="share"
                                    >
                                        <i class="ri-twitter-fill"></i>
                                    </div>
                                </share-network>
                            </li>
                            <li><share-network
                                    network="linkedin"
                                    :url="url"
                                    :title="post.title.rendered"
                                    :description="post.excerpt.rendered"
                                    hashtags="Safest"
                                    v-slot="{ share }"
                                >
                                    <div
                                        class="social-network linkedin"
                                        @click="share"
                                    >
                                        <i class="ri-linkedin-fill"></i>
                                    </div>
                                </share-network>
                            </li>
                            <li><share-network
                                    network="facebook"
                                    :url="url"
                                    :title="post.title.rendered"
                                    :description="post.excerpt.rendered"
                                    hashtags="Safest"
                                    v-slot="{ share }"
                                >
                                    <div
                                        class="social-network facebook"
                                        @click="share"
                                    >
                                        <i class="ri-facebook-fill"></i>
                                    </div>
                                </share-network>
                            </li>
                            <li><share-network
                                    network="email"
                                    :url="url"
                                    :title="post.title.rendered"
                                    :description='parseHtmlEntities(post.excerpt.rendered.replace(/(<([^>]+)>)/gi, ""))'
                                    hashtags="Safest"
                                    v-slot="{ share }"
                                >
                                    <div
                                        class="social-network email"
                                        @click="share"
                                    >
                                        <i class="ri-mail-fill"></i>
                                    </div>
                                </share-network>
                            </li>
                            <li>
                                <a class="copy" @click="copy_url()">
                                    <i class="ri-file-copy-fill"></i>
                                </a>
                            </li>
                            
                            <!-- <li><a href="#facebook" target="_blank" class="facebook"><i class="ri-facebook-fill"></i></a></li>
                            <li><a href="https://twitter.com/" target="_blank" class="twitter"><i class="ri-twitter-fill"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank" class="linkedin"><i class="ri-linkedin-fill"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank" class="instagram"><i class="ri-instagram-line"></i></a></li> -->
                        </ul>                        


                        <div class="is-layout-constrained entry-content wp-block-post-content" v-html="post.content.rendered">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <TechnicalSEOMarketing /> -->
        <!-- <YouMayAlsoLike />
        <LetsTalkSomething /> -->
    </div>
</template>

<script>
import NavbarTwo from '../Layout/NavbarTwoSafest'
/*import TechnicalSEOMarketing from '../BlogDetails/TechnicalSEOMarketing'*/
/*import YouMayAlsoLike from '../BlogDetails/YouMayAlsoLike'
import LetsTalkSomething from '../Common/LetsTalkSomething'*/

import $ from "jquery";

export default {
    name: 'blogDetail',
    components: {
        NavbarTwo
    },
    data(){
        return {
            post : {},
            featured_media: '',
            url: window.location.href,
            head : '',
            is_date : true,
        }
    },
    mounted() {
        this.on_load() 

    },
    unmounted(){
        document.querySelector('head').innerHTML = this.head

        this.$metas( {
            image : 'https://safestsurgery.eu/img/banner-safest.20a9d374.jpg',
        } )
    },
    methods : {
        on_load(){

            this.post = {}
            this.featured_media = ''
            this.url = window.location.href
            this.head = ''
            this.is_date = true

            let wp = this.$wp

            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('preview_id')
            const preview = urlParams.get('preview')
            

            if( preview )
            {
                this.wp_post('easy_api/v2/preview', {id:id}, (data)=>{
                    
                    console.log('revisions', data);

                    let date = new Date(data.date);
                    console.log( 'post', data.date );
                    this.post.display_date = date.toDateString()

                    this.post = data
                    this.post.title = {rendered : data.post_title}
                    this.post.content = {rendered : data.post_content}
                    this.post.excerpt = {rendered : data.post_excerpt}

                    this.update_content()
                } );    
            }
            else
            {
                wp.posts().slug( this.$route.params.slug ).get( ( err, data ) => {
                    
                    

                    this.post = data[0]

                    let date = new Date(this.post.modified);
                    console.log( 'post', this.post.modified );
                    this.post.display_date = date.toDateString()
                    
                    console.log('revisions', this.post);

                    this.update_content()
                         
                });
            }  
        },
        update_content(){


            let wp = this.$wp

            this.$metas( {
                title : this.post.title.rendered,
                description : this.post.excerpt.rendered,
            } )
            
            if( this.post.featured_media )
            {
                wp.media().id(this.post.featured_media).get( ( err, data ) => {

                    this.featured_media = data.media_details.sizes.full.source_url
                    this.$metas( {
                        image : this.featured_media,
                    } )
                });    
            } 

            let my_variable = new XMLHttpRequest(); // object
            let vue = this
            vue.head = document.querySelector('head').innerHTML
            my_variable.onload = function() {
                //console.log(this.response);
                let new_head = this.response + vue.head
                document.querySelector('head').innerHTML = new_head
            }
            
            console.log('css',this.post.id);

            my_variable.open("GET", /*this.post.link +*/ this.wp_website + "/?p="+this.post.id+"&head=1");

            my_variable.send();

            setTimeout( () => {
                $('.entry-content form').parent().addClass('contact-form')
                $('.entry-content label').parent().addClass('form-group')
                $('.entry-content label').next().addClass('form-control')
                $('input[type="checkbox"]').next('label').css('padding-top', '11px');
                $('.entry-content form').find('input[type="submit"]').addClass('default-btn style-two')

                $('.blog-details-desc .comment-respond').css({
                    'border': 'none',
                    'padding': '0px',
                    'box-shadow': 'none',
                    'margin-bottom': '50px'
                });

                $('table').addClass('table')
            }, 10 )
        },
        copy_url(){
            navigator.clipboard.writeText( this.url ).then(function() { 
                alert("Link copied to clipboard");
            });
        },
        parseHtmlEntities(str) {
            //console.log(he.decode(str));
            str = str.replace(/&#(\d+);/g, function(match, dec) {
                return String.fromCharCode(dec);
            });
            return str

        }
   
    },
    watch: {
      '$route.params.slug'() {
        
      },
    }
    
}
</script>

<style type="text/css">
    .article-content{
        min-height: 400px;
    }
    .blog-details-area .copy{
        cursor: pointer;
    }
</style>
<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Join Our EU-Funded Project to Enhance Patient Safety in Perioperative Care</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12">
                    <p>We are excited to invite hospitals across Europe and beyond to participate in an innovative, EU-funded project dedicated to improving patient safety practices throughout the perioperative journey.<br><br>
                    This initiative offers an opportunity for your institution to be at the forefront of enhancing care quality and safeguarding patient outcomes.<br><br>
                    We welcome hospitals of all sizes and specialisations. Your contribution can make a real difference in shaping safer practices and improving the overall quality of care worldwide.<br><br></p>
                    <p class="center-content">
                        <b>Click below to register your interest and join us on this important journey!</b>
                    </p>
                    <p class="center-content">
                        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfSgx7bfaMzZqUajpeYe_OL9LrjuOuUwkbkEZhKihd6cEXaMA/viewform" class="default-btn style-two">
                            <span class="ri-arrow-right-s-line"></span>Join our project</a>
                    </p><br>
                    <p class="center-content">
                        <b>For further details, visit the dedicated section here: </b>
                    </p>
                    <p class="center-content">
                        <a target="_self" href="https://safestsurgery.eu/standalone/perioperative-safety-initiative" class="default-btn style-two">
                            <span class="ri-arrow-right-s-line"></span>Perioperative Safety Initiative</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JoinUs'
}
</script>

<style scoped>
    .center-content {
        justify-self: center;
    }
</style>